interface ListItem {
	name: string;
	link: string;
}

interface ExploreSection {
	title: string;
	text?: string;
	lists?: ListItem[];
	all?: string;
}

interface BuiltForSection {
	title: string;
	text: string;
	link?: string;
}

interface StudyDatabaseSection {
	title: string;
	text: string;
}

interface ResearchSection {
	title: string;
	text: string;
}

export interface SupplementCategory {
	work?: string[];
	mayWork?: string[];
	avoid?: string[];
}

interface GuideSection {
	title: string;
	free: boolean;
	supplements: SupplementCategory[];
}

interface LatestScientificSection {
	title: string;
}

interface Tweet {
	name: string;
	position: string;
	comment: string;
}

interface Home {
	explores: ExploreSection[];
	builtFor: BuiltForSection[];
	studyDatabase: StudyDatabaseSection[];
	research: ResearchSection[];
	guides: GuideSection[];
	latestScientific: LatestScientificSection[];
	twitters: Tweet[];
}

export const home: Home = {
	explores: [
		{
			title: "Conditions",
			lists: [
				{
					name: "Anxiety",
					link: "/conditions/anxiety/",
				},
				{
					name: "ADHD",
					link: "/conditions/attention-deficit-hyperactivity-disorder/",
				},
				{
					name: "Depression",
					link: "/conditions/depression/",
				},
				{
					name: "High Blood Pressure",
					link: "/conditions/high-blood-pressure/",
				},
				{
					name: "High Cholesterol",
					link: "/conditions/high-cholesterol/",
				},
			],
			all: "/conditions/",
		},
		{
			title: "Supplements & More",
			lists: [
				{
					name: "Ashwagandha",
					link: "/supplements/ashwagandha/",
				},
				{
					name: "Creatine",
					link: "/supplements/creatine/",
				},
				{
					name: "Curcumin",
					link: "/supplements/curcumin/",
				},
				{
					name: "Magnesium",
					link: "/supplements/magnesium/",
				},
				{
					name: "Melatonin",
					link: "/supplements/melatonin/",
				},
			],
			all: "/interventions/",
		},
		{
			title: "Guides",
			lists: [
				{
					name: "Optimal protein intake",
					link: "/guides/protein-intake/",
				},
				{
					name: "How to Read a Study",
					link: "/guides/how-to-read-a-study/",
				},
				{
					name: "Memory and Focus",
					link: "/guides/memory-focus/",
				},
				{
					name: "Muscle Gain",
					link: "/guides/muscle-gain/",
				},
				{
					name: "Fat Loss",
					link: "/guides/fat-loss/",
				},
			],
			all: "/guides/",
		},
		{
			title: "Categories",
			lists: [
				{
					name: "Diabetes & Blood Sugar",
					link: "/categories/diabetes-blood-sugar/",
				},
				{
					name: "Fat Loss",
					link: "/categories/fat-loss/",
				},
				{
					name: "Muscle Gain & Exercise",
					link: "/categories/muscle-gain-exercise/",
				},
				{
					name: "Skin, Hair, & Nails",
					link: "/categories/skin-hair-nails/",
				},
				{
					name: "Sleep",
					link: "/categories/sleep/",
				},
			],
			all: "/categories/",
		},
	],
	builtFor: [
		{
			title: "Health Care Professionals",
			text: "There’s never been more health information widely available than today. Yet it’s more difficult than ever to find trustworthy sources. With no ads or industry ties, you can be confident Examine provides only unbiased research analysis, perfect for your patients and clients.",
		},
		{
			title: "Academia",
			text: "We take information infrastructure as seriously as nerds can. Everything on Examine is cited, with further links available to all summarized studies, helping you get the information you need at the appropriate depth for your research and studies.",
			link: "/plus/academic-libraries/",
		},
		{
			title: "Optimizing Health and Performance",
			text: "Examine is the home of cutting-edge research analysis. We summarize seven new studies every single day, keeping you ahead of the curve and maximizing your performance, training, and health optimization protocols.",
		},
		{
			title: "Chronic Health Conditions",
			text: "Health information can be overwhelming. We answer thousands of your most common questions to help you easily understand the nuance of specific health conditions. We get it: many of our team members are living life on hard mode, too.",
		},
	],
	studyDatabase: [
		{
			title: "Conditions",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
		},
		{
			title: "Interventions",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
		},
		{
			title: "Outcomes",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
		},
		{
			title: "Effect",
			text: "Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint. ",
		},
	],
	research: [
		{
			title: "Thorough",
			text: "Our research team pours through hundreds of studies and clinical trials to bring you concise health information",
		},
		{
			title: "Evidence-based",
			text: "We cite our sources, and allow you to delve as deep as we do",
		},
		{
			title: "Independent",
			text: "We don’t accept donors, sponsors, advertisements, or paid afiliations",
		},
		{
			title: "Transparent",
			text: "We keep our users updated when mistakes are identified as a result of our constant revision.",
		},
	],
	guides: [
		{
			title: "Allergies & Immunity",
			free: false,
			supplements: [
				{
					work: ["Vitamin A", "?", "?"],
				},
				{
					mayWork: ["Coconut oil", "Polypodium leucotomos", "?", "?", "?", "?"],
				},
				{
					avoid: ["Tinospora cordifolia", "?"],
				},
			],
		},
		{
			title: "Bone Health",
			free: false,
			supplements: [
				{
					work: ["Vitamin D", "Vitamin K", "?", "?", "?"],
				},
				{
					mayWork: ["Coral calcium", "?"],
				},
			],
		},
		{
			title: "Cardiovascular Health",
			free: false,
			supplements: [
				{
					work: ["Cocoa", "CoQ10", "Garlic"],
				},
				{
					mayWork: ["Pine bark", "?", "?"],
				},
				{
					avoid: ["Stimulants", "?"],
				},
			],
		},
		{
			title: "Diabetes & Blood Sugar",
			free: false,
			supplements: [
				{
					work: ["Zinc", "?", "?", "?", "?"],
				},
				{
					mayWork: ["Cinnamon", "?", "?"],
				},
				{
					avoid: ["Corosolic acid", "Vitamin C", "?", "?", "?", "?", "?", "?", "?", "?"],
				},
			],
		},
		{
			title: "Fat Loss",
			free: false,
			supplements: [
				{
					work: ["Protein", "EGCg", "?", "?", "?"],
				},
				{
					mayWork: ["Fiber", "MCTS", "?", "?", "?", "?"],
				},
				{
					avoid: ["Berberine", "Raspberry ketone", "?", "?", "?", "?"],
				},
			],
		},
		{
			title: "Healthy Aging",
			free: true,
			supplements: [
				{
					work: ["Protein", "Vitamin B", "?", "?", "?", "?", "?", "?", "?", "?"],
				},
				{
					mayWork: ["Vitamin E"],
				},
				{
					avoid: ["Vitamin B (folic acid)", "?", "?", "?", "?"],
				},
			],
		},
		{
			title: "Joint Health",
			free: false,
			supplements: [
				{
					work: ["Boswellia serrata", "Curcumin", "?", "?", "?", "?"],
				},
				{
					mayWork: ["MSM", "?", "?", "?"],
				},
				{
					avoid: ["CBD (cannabidiol)", "?"],
				},
			],
		},
		{
			title: "Libido & Sexual Function",
			free: false,
			supplements: [
				{
					work: ["Maca", "?", "?", "?"],
				},
				{
					mayWork: ["Cocoa", "Fenugreek", "?", "?"],
				},
				{
					avoid: ["Libido enhancers", "Testosterone boosters"],
				},
			],
		},
		{
			title: "Liver Health",
			free: false,
			supplements: [
				{
					work: ["Milk thistle", "TUDCA", "?", "?"],
				},
				{
					mayWork: ["Spirulina", "?", "?"],
				},
				{
					avoid: ["Alcohol"],
				},
			],
		},
		{
			title: "Memory & Focus",
			free: false,
			supplements: [
				{
					work: ["Caffeine + Theanine", "?", "?"],
				},
				{
					mayWork: ["Creatine", "Uridine", "?", "?", "?"],
				},
				{
					avoid: ["Nicotine", "?"],
				},
			],
		},
		{
			title: "Mood & Depression",
			free: false,
			supplements: [
				{
					work: ["St. John's Wort", "Zinc", "?", "?", "?", "?"],
				},
				{
					mayWork: ["NAC", "?"],
				},
				{
					avoid: ["Psychostimulants", "?"],
				},
			],
		},
		{
			title: "Muscle Gain & Exercise Performance",
			free: false,
			supplements: [
				{
					work: ["Creatine", "Nitrates", "?", "?", "?", "?"],
				},
				{
					mayWork: ["HMB", "?", "?", "?", "?", "?", "?"],
				},
				{
					avoid: ["Glutamine", "?"],
				},
			],
		},
		{
			title: "Skin, Hair & Nails",
			free: false,
			supplements: [
				{
					work: ["Vitamin A", "?", "?"],
				},
				{
					mayWork: ["Coconut oil", "Polypodium leucotomos", "?", "?"],
				},
				{
					avoid: [
						"Creams with vitamin E",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
						"?",
					],
				},
			],
		},
		{
			title: "Sleep",
			free: false,
			supplements: [
				{
					work: ["Magnesium", "?", "?"],
				},
				{
					mayWork: ["Lemon balm ", "?", "?"],
				},
				{
					avoid: ["Caffeine"],
				},
			],
		},
		{
			title: "Stress & Anxiety",
			free: false,
			supplements: [
				{
					work: ["Lavender", "?", "?", "?", "?"],
				},
				{
					mayWork: ["Ashwagandha", "?", "?", "?", "?"],
				},
				{
					avoid: ["Yohimbine", "?"],
				},
			],
		},

		{
			title: "Testosterone",
			free: false,
			supplements: [
				{
					work: ["DHEA", "?", "?", "?"],
				},
				{
					mayWork: ["Boron"],
				},
				{
					avoid: ["Libido enhancers", "Testosterone boosters", "?", "?", "?", "?"],
				},
			],
		},
		{
			title: "Vegetarians & Vegans",
			free: false,
			supplements: [
				{
					work: ["Iron + Vit. C", "?", "?", "?", "?", "?", "?", "?"],
				},
				{
					mayWork: ["Beta-alanine"],
				},
				{
					avoid: ["Omega 3-6-9"],
				},
			],
		},
	],
	latestScientific: [
		{
			title: "Allergies & Immunity",
		},
		{
			title: "Bone Health",
		},
		{
			title: "Cardiovascular Health",
		},
		{
			title: "Men's Health",
		},
		{
			title: "Fat Loss",
		},
	],
	twitters: [
		{
			name: "Aaron Almaraz",
			position: "CEO & Founder at Gearat",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
		{
			name: "Phet Putrie",
			position: "Freelancer",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
		{
			name: "Lara Madrigal",
			position: "Marketing Specialist",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
		{
			name: "Marleah Eagleston",
			position: "Founder at Spicenet",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
		{
			name: "Prescott MacCaffery",
			position: "Writer",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
		{
			name: "Waiapi Karaka",
			position: "Designer, Freelancer",
			comment:
				"Time is the most precious thing you have when bootstrapping. You can't take time to ponder on design…",
		},
	],
};
